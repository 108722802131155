/**
 * Use parcel on this file to build the main.js file
 */

/** Importing libraries */
// import moment from '../../node_modules/moment';
import * as bootstrap from '../../node_modules/bootstrap';
import Chart from '../../node_modules/chart.js/auto';
import * as FilePond from '../../node_modules/filepond';
// import { getRelativePosition } from 'chart.js/helpers';

/** Module Plugins */
import FilePondPluginImagePreview from '../../node_modules/filepond-plugin-image-preview/dist/filepond-plugin-image-preview.js';
import FilePondPluginFileValidateType from '../../node_modules/filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.js';

/** Module lang files */
import enEn from '../../node_modules/filepond/locale/en-en.js';
import esEs from '../../node_modules/filepond/locale/es-es.js';

/** Own functions */
import * as domManip from './modules/dom.js';
import * as domValidation from './modules/validation.js';
import * as random from './modules/random.js';
import * as chartBuilder from './modules/chartBuilder.js';

/** password generator */
var hdl = new Jen(true);
hdl.hardening(true);

/** Loading the bootstrap object into the window element to use globally */
window.bootstrap = bootstrap;

/** Adds popup HTML object to the page */
loadPopup();

if ( ENABLE_MODAL ) {
  globals.bsModal = new bootstrap.Modal('#modalContainer'); // #NOTE: Read Bootstrap's modal doc to learn how to use
  globals.modalContainer = document.getElementById('modalContainer'); // #NOTE: Points to DOM Element created by bsModal
}

/** Using modules/dom.js */
( () => {
  'use strict'

  /**
   * Default elements
   */
  /** Removes the need to click a date element's calendar icon */
  Array.from(document.querySelectorAll('input[type*="date"]:not(.datepicker-input), input[type*="time"]')).forEach( (dateInput) => {
    dateInput.addEventListener('click', (event) => event.target.showPicker() );
  });

  /** Enabling sidebar toggler */
  let sidebarToggle = document.querySelector('.sidebar-toggler');
  if (sidebarToggle) {
    sidebarToggle.addEventListener('click', (e) => {
      let sidebarId = e.target.closest('button').dataset.toggle;
      document.getElementById(sidebarId).classList.toggle('open');
    });
  }

  /** Search Controller */
  if ( globals.searchControl ) {
    globals.searchControl.addEventListener('click', (event) => {
      globals.searchControl.querySelector('input[name="p"]').value = 1;
    });

    // Ignoring empty inputs
    globals.searchControl.addEventListener('submit', (event) => {
      globals.searchControl.querySelectorAll('input, select').forEach((input) => {
        if ( !input.value ) {
          input.toggleAttribute('disabled');
        }
      });
    });
  }

  /**
   * Bootstrap Elements
   */
  /** Allows clicking a bootstrap dropdown within another bootstrap dropdown  */
  Array.from(document.querySelectorAll('a.dropdown-toggle')).forEach( (dropdown) => domManip.dropdownWithin(dropdown) );

  /** Enabling dark mode toggle */
  let modeSwitch = document.querySelector('.mode-switch');
  if (modeSwitch) {
    modeSwitch.addEventListener('click', (e) => {
      let body = document.body,
          payload = {};
      if (body.getAttribute('data-bs-theme') == 'dark') {
        body.setAttribute('data-bs-theme', 'light');
        payload['theme'] = 'light';
      } else {
        body.setAttribute('data-bs-theme', 'dark');
        payload['theme'] = 'dark';
      }

      makeRequest(BASE_URI+"settings.php", payload)
    });
  }

  /**
   * JQuery UI Elements
   */
  /** Sets up custom (JQuery UI) datepickers */
  document.querySelectorAll('.custom-datepicker').forEach((element) => {
    prepareDatePicker($(element));
  });

  /**
   * Chart JS Elements
   *  ** Use Jquery's .trigger() to fire chart changes
   */
  $('canvas.chart').each((i, element) => {
    $(element).on('change', (e) => {
      if (!e.target.dataset.chartQuery) {
        console.warn("No query bound to this canvas");
        return false;
      }

      let data, params, promise, options;

      data = {
        'stat': e.target.dataset.chartQuery,
        'params': {}
      };

      if (params = e.target.dataset.chartParams) {
        params = params.split('&');

        Array.from(params).forEach((param) => {
          param = param.split('=');
          if (param[0] && param[1]) {
            data['params'][param[0]] = param[1];
          }
        });
      }

      promise = makeRequest(`${BASE_URI}stats.php`, data, {'hidePopup': true});

      if (typeof promise == 'string') {
        promise = JSON.parse(promise);
      }

      promise.then((response) => {
        if (response) {
          options = {
            'chartType': e.target.dataset.chartType
          };
          response = JSON.parse(response);
          chartBuilder.chart(e.target, response, options);
        }
      })
    });
  });

  /**
   * Password-related elements
   */

  /**
   * Triggering password generators
   *  1. Looks for any .btn_generate_password elements
   *  2. Binds click event if data-target contains an existing elem
   */
  let passwordGenerators = document.querySelectorAll('.btn_generate_password');
  if (passwordGenerators) {
    passwordGenerators.forEach((el) => {
      el.addEventListener('click', (e)=> {
        let target = e.target.closest('.btn_generate_password').dataset.target;
        target = document.querySelector(`input#${target}`);
        if (target) {
          target.value = hdl.password(e.target.dataset.min, e.target.dataset.max);
        }
      });
    });
  }

  /**
   * Making password toggable
   *  1. Looks for any .password_toggler elements
   *  2. Binds click event if data-target contains an existing element
   */
  let passwordToggles = document.querySelectorAll('.password-toggler');
  if (passwordToggles) {
    passwordToggles.forEach((el) => {
      el.addEventListener('click', (e)=> {
        let target = e.target.closest('.password-toggler').dataset.target;
        target = document.querySelector(`input#${target}`);
        if (target) {
          switch(target.type) {
            case 'password':
              target.type = 'text';
              break;
            case 'text':
              target.type = 'password';
              break;
          }
        }
      });
    });
  }

  /**
   * File uploader (FilePond)
   *  Only one per page
   */
  // Registering FilePond plugins
  switch (globals.lang) {
    case 'es':
      FilePond.setOptions(esEs);
      break;
    default:
      FilePond.setOptions(enEn);
      break;
  }

  FilePond.registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateType);

  FilePond.setOptions({
    instantUpload: false,
    acceptedFileTypes: ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'],
    server: {
      url: BASE_URI,
      process: {
          url: './files',
          method: 'POST',
          headers: {
          //  'Cookie': `PHPSESSID=${SESSION}`,
           'x-testname': 'test-custom-header',
          },
          withCredentials: false,
          onload: (response) => response.key,
          onerror: (response) => response.data,
          ondata: (formData) => {
            formData.append('csrf_token', CSRF_TOKEN);
            return formData;
          },
      },
      // revert: './revert',
      // restore: './restore/',
      // load: './load/',
      // fetch: './fetch/',
    },
  });

  window.pond = FilePond.create(document.querySelector('input#file-uploader'));

  let uploader = document.getElementById('uploader-watch');
  if (uploader) {
    uploader.addEventListener('click', (e) => {
      window.pond.processFiles();
    });
  }

})();

/** Using modules/forms.js */
( () => {
  // 'use strict'

  const validationForms = document.querySelectorAll('form');

  validationForms.forEach( (form) => {
    form.addEventListener('submit', (e) => {
      if (e.target.matches('.needs-validation')) {
        if ( !form.checkValidity() ) {
          e.preventDefault();
          e.stopPropagation();
  
          domValidation.validateForm(e.target);
        }
  
        form.classList.add('was-validated');
      }

      form.appendChild(csrf);
    }, false);
  });
})();
