/**
 * This file functions to build charts
 */
import Chart from '../../../node_modules/chart.js/auto';

var chartList = {};

// #TODO: Make chart not destroy 
function buildChart(canvas, dataset, options = {}) {
  if (!['string', 'object'].indexOf(typeof canvas)) {
    console.error('Parameter 1 must be an id string or a HTML DOM Element.');
    return false;
  }

  let data, config, chartType;

  if (typeof canvas == 'string') {
    canvas = document.getElementById(`canvas#${canvas}`);

    if (!canvas) {
      console.error("No canvas element found");
      return false;
    }
  }

  // If a donut chart already exists, destroy it
  if ( chartList[canvas.id] ){ chartList[canvas.id].destroy(); }

  if ( canvas == false ) {
    console.error('No element with id chartjs-donut was found');
    return false;
  }

  data = {
    labels: Object.keys(dataset),
    datasets: [{
      label: 'My First Dataset',
      data: Object.values(dataset),
      hoverOffset: 4
    }],
    options: [{
      responsive: true,
      maintainAspectRatio: true,
    }]
  };

  chartType = 'bar';
  if (options['chartType']) {
    chartType = options['chartType'];
  }

  config = {
    type: chartType,
    data: data,
  };

  chartList[canvas.id] = new Chart(
    canvas,
    config
  );

}

export { buildChart as chart }