/**
 * Functions that generate random values
 * 
 * #NOTE: Do not rely on these for security reasons
 */

// Simple Math.random() from: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
function getRandomRange(min, max) {
  if ( !Number.isInteger(min) || !Number.isInteger(max) ) {
    console.error('Min or Max value is not of type Integer');
    return false;
  }

  return Math.floor(Math.random() * (max - min) + min);
}

// Random Hex Generator fix from user Ken in https://css-tricks.com/snippets/javascript/random-hex-color/
function getRandomHex() {
  let x = Math.round( 0xffffff * Math.random() ).toString(16);
  let y = ( 6 - x.length );
  let z = "000000";
  let z1 = z.substring(0, y);
  return  "#" + z1 + x;
}

export { getRandomRange as range, getRandomHex as hex};