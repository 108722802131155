/**
 * Module containing functions that alter DOM Elements
 */

function clickDropdownWithin(dropdown) {
  dropdown.addEventListener('click', (e) => {
    let dropdownWithin = e.target.closest('li')
    if ( dropdownWithin != null  ) {
      e.target.closest('li').classList.toggle('open');
      e.stopPropagation();
      e.preventDefault();
    }
  });
}

export { 
  clickDropdownWithin as dropdownWithin
};