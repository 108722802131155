/* *
 *  Module containing functions that allow to validate entire forms or specific elements
 */

// Expects a form node
//  If one of its inputs is invalid, it will will search for an 
//    .invalid-feedback block to display a message, message differs for each error type
function validateForm(form) {
  const invalidInputs = form.querySelectorAll('input:invalid');

  Array.from(invalidInputs).forEach( (input) => {
    // Input-related vars
    const validityState = input.validity;
    let feedbackMessage = input.parentNode.querySelector('.invalid-feedback');

    // Validation-related vars
    let patternLengthMatches = validateInputLengthUsingPattern(input);

    // #TODO: Missing validityState validation messages for:
    //  badInput
    //  patternMismatch (on accepted char-types)
    //  stepMismatch
    //  typeMismatch
    //  customError (optional)

    if ( validityState.valueMissing ) {
      feedbackMessage.innerText = `Please fill this field.`;
    } else if ( validityState.rangeUnderflow ) {
      feedbackMessage.innerText = `Field must be greater than ${input.getAttribute('min')}.`;
    } else if ( validityState.rangeOverflow ) {
      feedbackMessage.innerText = `Field must be lower than ${input.getAttribute('max')}`;
    } else if ( validityState.tooShort || patternLengthMatches.tooShort) {
      feedbackMessage.innerText = `Field must contain at least ${input.getAttribute('minlength')|patternLengthMatches.minLength} characters`;
    } else if ( validityState.tooLong || patternLengthMatches.tooLong) {
      feedbackMessage.innerText = `Field must not be longer than ${input.getAttribute('maxlength')|patternLengthMatches.maxLength} characters`;
    }
  });
}

// Parameter input node, should contain a pattern attribute specifying a string length
// Returns object specifying the minlength, maxlength and if the input's value follows the required length
function validateInputLengthUsingPattern(input) {
  let inputPattern = input.getAttribute('pattern'),
      lengthValues = false,
      result = {};

  if ( !inputPattern ) { return false; }

  /* *
  *  This pattern is used to check if the input's pattern validates length
  *    Expected patterns are: '.{5,}'  '[A-Z]{5,}' and '.{5,8}'
  *      where the first digit indicates min-length and the second digit uses max-length
  *  This is done because a browser's autofill may bypass minlength attribute
  */
  let lengthPattern = /\{(\d+),(\d*)\}$/;

  lengthValues = inputPattern.match(lengthPattern);

  // If a match is found...
  if ( lengthValues ) {
    // The minimum length must be present, else it is not a valid pattern
    result.minLength = lengthValues[1];

    // And the first match is greater than the input, value is too short
    if ( parseInt(lengthValues[1]) > input.value.length ) { result.tooShort = true; }
    else if ( lengthValues[2] ) {
      result.maxLength = lengthValues[2];
      // Else, if a second match match is found and the input is greater, value is too long
      if ( input.value.length > parseInt(lengthValues[2]) ) { result.tooLong = true; }
    }
  }

  return result;
}

export { 
  validateForm, 
  validateInputLengthUsingPattern as validateInputLength 
};